import Link from "next/link";
import DiscordLogo from "src/icons/V2/Social/Discord.svg";
import TwitterLogo from "src/icons/V2/Social/Twitter.svg";

const TERMS_LINK =
  "https://public-pdfs.s3.amazonaws.com/Hello+Moon+Terms+of+Service+(WSGR+3.11.22).+(4894-5553-0764.pdf";

const HOTJAR_LINK =
  "https://surveys.hotjar.com/546656fc-d96f-40af-b099-0d3034277b9b";

/**
 * This is a simplified footer for v1 until we have the pages specified by the other footer.
 */
export default function FooterV2() {
  return (
    <footer className="bg-hmio-black-950 py-9 px-6 text-hmio-black-400 text-sm">
      <section className="flex justify-between flex-col gap-10 md:flex-row md:gap-6 items-center">
        <article>
          <ul className="flex gap-10">
            <li className="py-2 cursor-pointer">
              <Link href={TERMS_LINK}>
                Privacy, Security, Terms & Conditions
              </Link>
            </li>
            <li className="py-2 cursor-pointer">
              <Link href={HOTJAR_LINK}>Business Inquiries</Link>
            </li>
            <li className="py-2 cursor-pointer">
              <Link href={HOTJAR_LINK}>Contact</Link>
            </li>
          </ul>
        </article>
        <article className="max-w-xs flex gap-10">
          <div className="flex gap-4">
            <Link href="https://discord.gg/HelloMoon" passHref>
              <a className="cursor-pointer">
                <DiscordLogo className="fill-hmio-black-400" />
              </a>
            </Link>
            <Link href="https://twitter.com/HelloMoon_io" passHref>
              <a className="cursor-pointer">
                <TwitterLogo className="fill-hmio-black-400" />
              </a>
            </Link>
          </div>
          <p className="pt-0.5">&#169; 2022 Hello Moon</p>
        </article>
      </section>
    </footer>
  );
}
